@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$primary: #967bb6;
$light-primary: #9bb67b;
$white: #fff;
$box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .2);
$bold: 600;

@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin container {
  width: 80%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 80px 0;

  @include mobile {
    width: 90%;
  }
}

@mixin social {
  display: flex;

  a {
    display: flex;

    .icon {
      transition: all 0.2s ease;
      width: 36px;
      margin-right: 20px;
      fill: $white;
      align-items: center;

      &:hover {
        cursor: pointer;
        fill: $light-primary;
      }
    }
  }
}
