@import './variables';

html {
  font-family: 'Open Sans';
  margin: 0;

  body {
    margin: 0;

    h3 {
      font-size: 36px;
      font-weight: 600;
      color: $primary;

      @include mobile {
        font-size: 28px;
      }
    }

    p {
      font-size: 24px;
      line-height: 36px;

      @include mobile {
        font-size: 20px;
        line-height: 32px;
      }
    }
    
    a {
      transition: all 0.2s ease;
      color: $white;
      text-decoration: none;

      &:hover {
        color: $light-primary;
      }
    }
  }
}
