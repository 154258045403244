@import "./variables";

.app {
  header {
    transition: all 0.2s ease;
    width: 100%;
    height: 80px;
    background: $primary;
    color: $white;
    font-size: 20px;
    z-index: 100;

    .header-logo {
      height: 70px;
    }

    .header-content {
      @include container;
      display: flex;
      padding: 0;
      height: 100%;
      align-items: center;
      justify-content: space-between;

      .link {
        transition: all 0.2s ease;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: $light-primary;
        }
      }

      .header-links {
        .link {
          margin: 0 10px;
        }
      }
    }

    &.active {
      box-shadow: $box-shadow;
      position: fixed;
      background: $white;
      color: $primary;
      top: -80px;
      transition: transform 0.5s ease-out;
      transform: translateY(80px);

      a {
        color: $primary;

        &:hover {
          color: $light-primary;
        }
      }
    }
  }

  .hero {
    font-weight: $bold;
    width: 100%;
    height: 600px;
    font-size: 72px;
    color: $white;
    background: $primary;

    @include mobile {
      height: 800px;
      font-size: 58px;
    }

    .hero-content {
      @include container;
      padding-top: 160px;

      @include mobile {
        padding-top: 60px;
        width: 80%;
      }

      .hero-blurb {
        margin: 30px 0 40px;
        font-size: 30px;
        line-height: 48px;

        @include mobile {
          font-size: 28px;
          line-height: 42px;
        }
      }

      .hero-social {
        @include social;
      }
    }
  }

  .projects {
    @include container;

    .projects-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;

      .project {
        box-shadow: $box-shadow;
        background-size: cover;
        height: 280px;
        width: 460px;
        margin: 30px 0;
        position: relative;

        @include mobile {
          width: 300px;
          height: 200px;
        }

        .project-image {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
        }

        .project-content {
          display: flex;
          transition: all 0.2s ease;
          flex-direction: column;
          font-weight: 700;
          text-align: center;
          background: rgba(0, 0, 0, 0.1);
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color: $primary;
          font-size: 36px;
          opacity: 0;

          .project-subtitle {
            font-weight: $bold;
            font-size: 16px;
            margin-bottom: 10px;
          }

          .project-links {
            display: flex;
            justify-content: space-between;

            a {
              font-weight: $bold;
              text-decoration: none;
              background: $white;
              color: $primary;
              font-size: 18px;
              padding: 8px;
              border: 1px solid $primary;

              &:nth-child(1) {
                margin-right: 10px;
              }

              &:hover {
                color: $light-primary;
                border: 1px solid $light-primary;
              }
            }
          }
        }

        &:hover {
          .project-image {
            filter: blur(6px);
          }

          .project-content {
            opacity: 1;
          }
        }
      }
    }
  }

  .about {
    .about-content {
      @include container;
    }
  }

  .contact {
    background: $primary;
    padding-bottom: 50px;

    h3 {
      color: $white;
    }

    .contact-content {
      @include container;

      .contact-social {
        @include social;
        justify-content: center;
      }
    }
  }
}
